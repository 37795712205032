import { Controller } from "@hotwired/stimulus"
import { get, post, put } from "@rails/request.js"

export default class extends Controller {
  static targets = ["button"]
  static values = {
    clientDisplayName: {
      type: String,
      default: "No Logo X"
    },
    environment: {
      type: String,
      default: "sandbox" // "sandbox" | "staging" | "production"
    },
    creatorId: String,
    userId: String,
    token: String
  }

  initialize() {
    this.sdk = PhylloConnect
    this.config = {
      clientDisplayName: this.clientDisplayNameValue,
      environment: this.environmentValue,
      userId: this.userIdValue,
      token: this.tokenValue
    }
  }

  connect() {
    this.tokenValue ? this.initializeInsightiq() : this.fetchSDKToken()
    // this.initializeInsightiq()
  }

  initializeInsightiq() {
    if (!this.tokenValue) return

    // Phyllo is the legacy name for InsightIQ and their SDK is used
    this.insightiq = this.sdk.initialize(this.config)
    this.buttonTargets.forEach(element => (element.disabled = false))

    // Registering event handlers
    this.insightiq.on("accountConnected", this.handleAccountConnected)
    this.insightiq.on("accountDisconnected", this.handleAccountDisonnected)
    this.insightiq.on("tokenExpired", this.handleTokenExpired)
    this.insightiq.on("connectionFailure", this.handleConnectionFailure)
    this.insightiq.on("exit", this.handleExit)
  }

  // Actions
  open() {
    if (!!this.insightiq) this.insightiq.open()
  }

  async fetchSDKToken() {
    const response = await get(`/insightiq/sdk_token?creator_id=${this.creatorIdValue}`, { responseKind: "json" })
    if (response.ok) {
      console.log(await response.json)
      const token = (await response.json)["sdk_token"]
      if (token?.length) this.tokenValue = token
    }
  }

  // Callbacks
  tokenValueChanged() {
    this.config["token"] = this.tokenValue
    if (this.tokenValue) this.initializeInsightiq()
  }

  // Handlers
  async handleAccountConnected(insightiq_account_id, insightiq_work_platform_id, insightiq_user_id) {
    console.group("[InsightIQ] Account Connected")

    const payload = { insightiq_account_id, insightiq_work_platform_id, insightiq_user_id }
    await post("/insightiq/connect_account", { responseKind: "json", body: JSON.stringify(payload) })

    setTimeout(() => {
      window.location.reload()
    }, 2000)
    console.groupEnd()
  }

  handleAccountDisonnected(insightiq_account_id, insightiq_work_platform_id, insightiq_user_id) {
    console.group("[InsightIQ] Account Disconnected")
    const payload = { insightiq_account_id, insightiq_work_platform_id, insightiq_user_id }
    put("/insightiq/disconnect_account", { responseKind: "json", body: JSON.stringify(payload) })

    console.groupEnd()
  }

  handleTokenExpired(userId) {
    console.group("[InsightIQ] Token Expired")
    // console.log("User ID", userId)
    console.groupEnd()
  }

  handleExit(reason, userId) {
    console.group("[InsightIQ] Exit")
    // console.log("Reason", reason)
    // console.log("User ID", userId)
    console.groupEnd()
  }

  handleConnectionFailure(reason, workplatformId, userId) {
    console.group("[InsightIQ] Connection Failure")
    // console.log("Reason", reason)
    // console.log("Work Platform ID", workplatformId)
    // console.log("User ID", userId)
    console.groupEnd()
  }
}
