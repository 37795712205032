import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

// Controllers
import { Autocomplete } from "stimulus-autocomplete"
import Timeago from "stimulus-timeago"
import Notification from "stimulus-notification"
import CheckboxSelectAll from "stimulus-checkbox-select-all"
import CharacterCounter from "stimulus-character-counter"

const application = Application.start()

application.debug = process.env.NODE_ENV === "development"
application.warnings = true
window.Stimulus = application

application.register("timeago", Timeago)
application.register("autocomplete", Autocomplete)
application.register("notification", Notification)
application.register("checkbox-select-all", CheckboxSelectAll)
application.register("character-counter", CharacterCounter)

try {
  const controllers = import.meta.glob("../controllers/**/*_controller.js", { eager: true })
  registerControllers(application, controllers)
} catch (error) {
  console.error("Error registering Stimulus controllers:", error)
}

import "../channels/index.js"

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log("Vite ⚡️ Rails")

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log("Visit the guide for more information: ", "https://vite-ruby.netlify.app/guide/rails")

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
