import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["result"]

  connect() {
    // console.log(this.element)
  }

  select(event) {
    this.dispatch("location-selected", {
      detail: { latitude: event.params.latitude, longitude: event.params.longitude, text: event.params.text }
    })
  }
}
