import { Controller } from "stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = { maxItems: Number, customValues: Array, searchField: String, valueField: String }

  connect() {
    this.initTomSelect()
  }

  disconnect() {
    if (this.select) this.select.destroy()
  }

  initTomSelect() {
    const options = {
      maxItems: this.hasMaxItemsValue ? this.maxItemsValue : null
    }

    if (this.hasCustomValuesValue) {
      options["valueField"] = this.valueFieldValue
      options["searchField"] = this.searchFieldValue
      options["options"] = this.customValuesValue
    }

    this.select = new TomSelect(this.element, options)
  }
}
