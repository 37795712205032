import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fillable", "disableable"]
  static values = {
    kind: {
      type: String,
      default: "all" // or "single"
    }
  }
  connect() {
    this.toggle()
  }

  toggle(event) {
    let disabled = true
    disabled = !this.fillableTargets.filter(fillable => !!fillable.value.length).length
    this.disableableTargets.forEach(disableable => (disableable.disabled = disabled))
  }
}
